@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Permanent+Marker&family=Rubik+Iso&display=swap');

:root {
  --yellow: #e0f300;
  --magenta: #ff3beb;
  --cyan: #00ffc3;
  --blue: royalblue;
  --white: white;
  --BORDER_RADIUS: 5px;
}

* {
  font-family: 'Audiowide', cursive;
  color: var(--white);
}

a, a:hover {
  font-family: 'Audiowide', cursive;
  color: var(--white);
  text-decoration: none;
}


/***********************************************************************/
/******************************  MOBILE   ******************************/
/***********************************************************************/

@media only screen and (max-width: 1199px){

  .only-full {
    display:none;
  }

  .game {
    padding: 1%;
    height: 70vh;
    width: 100vw;
  }

  .pad-left {
    position: fixed;
    bottom: 0vh;
    left: 0vw;
  }

  .pad-right {
    position: fixed;
    bottom: 0vh;
    right: 0vw;
  }

  .button {
    display: block;
    width: 15vw;
    margin: 5px;
    padding-top: 0.3rem;
    border-radius: var(--BORDER_RADIUS);
    border: none;
    color: var(--white);
  }

  .button-header {
    width: 95vw;
    border-radius: var(--BORDER_RADIUS);
    border: none;
    color: var(--white);
  }

  .score {
    position: fixed;
    bottom: 0vh;
    left: 42vw;
    padding: auto;
  }

  .header {
    margin-top: 5px;
    font-size: 1.9rem;
    font-weight: 700;
    text-align: center;
    border-radius: var(--BORDER_RADIUS);
  }

  .faq{
    margin: 5vw;
  }
  
}

/***********************************************************************/
/******************************  DESKTOP  ******************************/
/***********************************************************************/

@media only screen and (min-width: 1200px){

  .only-reduce {
    display:none;
  }
  
  .game {
    padding: 1%;
    height: 100vh;
    width: 100vw;
  }

  .play {
    position: fixed;
    top: 45vh;
    left: 75vw;
    width: 15vw;
    perspective: 500px;
  }

  .launch {
    position: fixed;
    top: 20vh;
    left: 10vw;
    width: 15vw;
    perspective: 500px;
  }

  .score {
    position: fixed;
    top: 20vh;
    left: 75vw;
    width: 15vw;
    perspective: 500px;
    border-radius: var(--BORDER_RADIUS);
  }

  .faq {
    position: fixed;
    top: 25vh;
    left: 35vw;
    width: 30vw;
  }

  @keyframes rotateLaunchpad {
    0% {
      transform: rotateY(0deg) rotateX(10deg);
      animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
      box-shadow:4px 4px 6px 2px var(--magenta);
    }
    25% {
      transform: rotateY(20deg) rotateX(10deg);
    }
    50% {
      transform: rotateY(0deg) rotateX(10deg);
      animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
      box-shadow:4px 4px 6px 10px var(--magenta);
    }
    75% {
      transform: rotateY(-20deg) rotateX(10deg);
    }
    100% {
      transform: rotateY(0deg) rotateX(10deg);
      box-shadow:4px 4px 6px 2px var(--magenta);
    }
  }

  .move {
    border-radius: var(--BORDER_RADIUS);
    display: flex;
    flex-direction: column;
    transform: rotatex(10deg);
    animation: rotateLaunchpad 20s linear infinite;
  }

  .button {
    display: block;
    margin: 10px;
    padding: 0.5rem 2rem;
    border-radius: var(--BORDER_RADIUS);
    font-size: 1.2rem;
    font-weight: 700;
    border: none;
    transition: all .2s;
    cursor: pointer;
    text-transform: uppercase;
    color: var(--white);
    mix-blend-mode: color-dodge;
  }

}

/***********************************************************************/
/******************************  COMMON  *******************************/
/***********************************************************************/



html,
#root {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  background-image: url("https://skranf.me/bg_pongroyale.jpg");
  background-size: cover;
}




.input {
  display: inline-block;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  text-transform: uppercase;
  color: var(--white);
}

.magenta, .magenta:hover {
  background-color: var(--magenta);
  box-shadow:1px 1px 4px 1px var(--magenta);
}

.cyan, .cyan:hover {
  background-color: var(--cyan);
  box-shadow:1px 1px 4px 1px var(--cyan);
}



	

	
